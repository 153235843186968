import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  return !!userData; // Returns true if userData exists, false otherwise
};

const PrivateRoute = () => {
  const isAuthenticated = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? <Outlet /> : null;
};

export default PrivateRoute;
