import React, { useEffect, useState,useRef } from 'react';
import axios from 'axios';
import one from '../../Images/one.jpg';
import two from '../../Images/two.jpg';
import three from '../../Images/three.jpg';
import five from '../../Images/six.jpg';
import Ayadha from '../../Images/ayadhya.jpg'
import '../Banner/Banner.css';
import Service from '../Services/Service';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { globalapi } from '../../GlobalApi';



const Banner = () => {
  const [responseData, setResponseData] = useState([]);

//getHomeInfo1&link=sumondigital
useEffect(() => {
  axios
  .get(globalapi+`getnature4banner`)
   // .get(`https://bsoapp.in/servicefinder/api/dev/api_wbp1.php?action=getnature4banner`)
    .then((res) => {
      const userbennr = res.data;
      const fainalbanner = userbennr.banners;

      // Check if the fetched data is different from the existing state before updating
      if (JSON.stringify(fainalbanner) !== JSON.stringify(responseData)) {
        setResponseData(fainalbanner);
      }
    })
    .catch((err) => {
      console.warn(err);
    });
}, []);

const images = responseData.map(banner => banner.image);

  var settings = {
  dots: true,
  infinite: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true, // Enable autoplay
  autoplaySpeed: 5000 // Set the speed (time in milliseconds)
  };


// What we have Section..........

  const haveimage = [one,two,three]
  const carouselRef = useRef(null);
  let currentImage = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      currentImage = (currentImage + 1) % haveimage.length;
      if (carouselRef.current) {
        carouselRef.current.src = haveimage[currentImage];
      }
    }, 4000); // Change image every 4 seconds

    return () => clearInterval(interval);
  }, []);
  

  const [counterStart, setcounterStart] = useState(false)

  return (
   <>
    <div className="imageslider">
 <Slider {...settings}>
      {images.map((image, index) => (
        <div key={index} className='divimage'>
        <img src={image} alt={`Image ${index + 1}`} />
        </div>
      ))}
    </Slider>
    </div>
    <div className="services">
     <Service/>
    </div>


{/* Start */}
    <div className="whywebest">
    <h3>if You Ask <span className='specialwwh'>What We Have ?</span></h3>
    <div className="mainwhywebest">
    <div className="imgedivs">
    <img
            ref={carouselRef}
            src={haveimage[currentImage]} // Initial image
            alt="carousel"
          />
    </div>
    <div className="textdivs">
    <div className="textss">
     <h4>Touch Of Nature <i class="fa-solid fa-leaf"></i></h4>
     <p>Purulia, a district nestled in the eastern part of India's West Bengal state, is a treasure trove of natural beauty and cultural richness. Its undulating landscape is adorned with rolling hills, dense forests, and sparkling water bodies. The red soil, characteristic of this region, imparts a unique charm to its surroundings. The district's serene and picturesque landscape boasts a stunning amalgamation of lush greenery and vibrant tribal culture. One can witness the mesmerizing dance of Palash flowers, painting the region in vivid hues during the spring season. The Ajodhya Hills, with their stunning vistas, and the historical remnants like the Panchakot Rajbari and ancient temples, add a touch of mystery and allure. The traditional Chhau dance, a unique and vibrant folk art form, further enriches the cultural tapestry of Purulia. As the sun sets, painting the skies with myriad colors, one can truly feel the tranquil beauty and the unassuming yet magnetic allure of Purulia.</p>
    </div>
    </div>
    </div>
    </div>
    {/* End */}

<div className="servingexperincw">
{/* One Div */}
<div className="expone">
{/* ////// */}

<ScrollTrigger onEnter={()=> setcounterStart(true)} onExit={()=> setcounterStart(false)}>
  <div className="happycustomer">
  <div className='underdiv'>
  {counterStart &&  <h4><CountUp end={500} duration={8}/>+</h4>}
  <p> <i class="fa-solid fa-user"></i> Happy Customer</p>
  </div>
  </div>
</ScrollTrigger>

  {/* ///// */}
  <ScrollTrigger onEnter={()=> setcounterStart(true)} onExit={()=> setcounterStart(false)}>
  <div className="fooditems">
  <div className='underdiv'>
  {counterStart &&  <h4><CountUp end={90} duration={8}/>+</h4>}
  <p> <i class="fa-solid fa-utensils"></i> Food Items</p>
  </div>
  </div>
  </ScrollTrigger>
</div>
{/* two div start */}
<div className="exptwo">

<ScrollTrigger onEnter={()=> setcounterStart(true)} onExit={()=> setcounterStart(false)}>
  <div className="rooms">
  <div className='underdiv'>
  {counterStart &&  <h4><CountUp end={40} duration={8}/>+</h4>}
  <p> <i class="fa-solid fa-person-shelter"></i>Nature Room Available</p>
  </div>
  </div>
</ScrollTrigger>

<ScrollTrigger onEnter={()=> setcounterStart(true)} onExit={()=> setcounterStart(false)}>
  <div className="experince">
  <div className='underdiv'>
  {counterStart &&  <h4><CountUp end={800} duration={8}/>+</h4>}
  <p> <i class="fa-solid fa-mountain-city"></i> Tourist Sport</p>
  </div>
  </div>
</ScrollTrigger>


</div>
</div>


    </>
  );
};

export default Banner;
