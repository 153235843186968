import React, { useEffect, useState } from 'react';
import '../FoodPage/Foodmenu.css';
import { Fooddata } from '../../Fooddata/Food';
import Delivery from '../../Images/Delivery.png'
import Dinner from '../../Images/Dineout.png'
import Egg from '../../Images/Egg.jpg'
import { useFoodCart } from '../FoodContext';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { globalapi } from '../../GlobalApi';



const Foodmenu = () => {
  const [showDelivery, setShowDelivery] = useState(false); 

  const { dispatch } = useFoodCart();

const [catagorydata, setcatagorydata] = useState([])


useEffect(()=>{
 //axios.get(globalapi+`getSubcatinfo&scid=21677&cat_id=33&subcat_level=1&company_id=1`)
 axios.get(globalapi+`getSubcatinfo&scid=21702&cat_id=33&subcat_level=1&company_id=19269`)
 //21702
 .then((res)=>{
const  data = res.data
const mainData = data.catinfo
  setcatagorydata(mainData)
 })
},[])
// Catagory food data hear

//const [data, setData] = useState(21679); // Initialize data with an empty string
const [data, setData] = useState(21704); // Initialize data with an empty string

const [foods, setfoods] = useState([])

useEffect(() => {
  if (data) { // Check if data is not empty
    axios
      .get(globalapi+`getSubcatinfo&scid=${data}&cat_id=33&subcat_level=1&company_id=19269`)
      .then((res) => {
        const maindatafood = res.data
        const foodmaindatais = maindatafood.catinfo

        sessionStorage.setItem('foodData', JSON.stringify(foodmaindatais));
        setfoods(foodmaindatais);

        console.log(foods)
      })
      .catch((err) => {
        console.warn(err);
      });
  }
}, [data]);

const storedFoodData = sessionStorage.getItem('foodData');
const foodData = storedFoodData ? JSON.parse(storedFoodData) : [];

  console.log(data)
  console.log(foods)

  const handleCategoryClick = (categoryId) => {
    // Update the 'data' state when a category banner is clicked
    setData(categoryId);
  };

  console.log('foods',foodData)


  return (
    <>
      {/* <div className="selectone">
        <div className="dilivery" onClick={() => setShowDelivery(false)}>
          <img src={Delivery} alt="" />
          <p>Delivery</p>
        </div>
        <div className="DineOut" onClick={() => setShowDelivery(false)}>
          <img src={Dinner} alt="" />
          <p>DineOut</p>
        </div>
      </div> */}

      {/* Food Delivery */}
      {showDelivery ? (
        <>
          <h3 className='whatsinyourmind'>What's on your mind ?</h3>
          <div className="horizontal-scroll-container">
            {Fooddata.map((item, index) => (
              <div key={item.id} className='mainfoodmenu'>
                <div className="fooditeam">
                  <img src={item.image} alt="" />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        // Render content for DineOut here
        <>
        <h3 className='dineoutheadr'>Select your food</h3>
        <div className="dineout-content">
  
        <div class="scrollable-container">

{
  catagorydata.map((food,index)=>{
    return(
      <>
      {/* {`navbar2${isSticky ? ' navbar2 sticky' : ''}`} */}
      {/* fullday */}
      <div class="fullday" onClick={() => handleCategoryClick(food.id)}>
      <h4>{food.scatname}</h4> 
           
             </div>
      </>
    )
  })
}
           



        </div>
    </div>

    <div className="Foods">
{
  foodData.map((foodmenu,index)=>{
    return(
      <div className="foodcard">
        <div className="foodname">
          <h4>{foodmenu.scatname}</h4>
          <p><i class="fa-solid fa-star"></i> {foodmenu.text6}</p>
          <h5><del className='scrtachtext'>₹{foodmenu.scatprice}</del> ₹{foodmenu.currentprice}</h5>
          <p className='discription'>{foodmenu.text1}</p>
          <p className='discription'>{foodmenu.text2}</p>
          <p className='discription'>{foodmenu.text3}</p>
          <p className='discription'>{foodmenu.text4}</p>
          <p className='discription'>{foodmenu.text5}</p>
        </div>
        <div className="foodimage">
        <div className="buttonimage">

          <img src={foodmenu.scatpicture} alt="" />
          <button  onClick={() => {
                  dispatch({
                    type: 'ORDER_FOOD',
                    payload: {
                    foodid: foodmenu.id,
                    foodname: foodmenu.scatname,
                    foodquantity: 1,
                    foodprice: foodmenu.currentprice,
                    foodimage: foodmenu.scatpicture,
                    catagoryid: foodmenu.cat_id,
                    item_no: foodmenu.itemno,
                    displayname: foodmenu.subcatorderdispnote,
                    origenalprice: foodmenu.scatprice,
                    catagory_unite_discount: foodmenu.scatdisunitname

                      
                    },
                  });
                  toast.success('Add To Cart Sucessfully');
                }}>Add To Cart <i class="fa-solid fa-circle-plus"></i></button>
        </div>
        </div>
      </div>


    )
  })
}



<ToastContainer

/>
      
     
   

    </div>
    </>

      )}
    </>
  );
};

export default Foodmenu;
