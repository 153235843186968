import React, { createContext, useReducer, useContext, useEffect } from 'react';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      case 'ADD_TO_CART':
        const itemToAdd = action.payload;
        const itemInCart = state.cartItems.find((item) => item.id === itemToAdd.id);
      
        if (itemInCart) {
          // If the item is already in the cart, increment its quantity
          itemInCart.quantity = 1;
        } else {
          // If the item is not in the cart, add it with a default quantity of one
          itemToAdd.quantity = 1; // Set the default quantity to 1
          state.cartItems.push(itemToAdd);
        }
      
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      
        return {
          ...state,
          cartItems: [...state.cartItems],
        };
      

    case 'REMOVE_FROM_CART':
      const updatedCartItems = state.cartItems.filter(
        (item) => item.id !== action.payload.id
      );
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
      return {
        ...state,
        cartItems: updatedCartItems,
      };

    case 'INCREMENT_QUANTITY':
      const increasedCart = state.cartItems.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
      localStorage.setItem('cartItems', JSON.stringify(increasedCart));
      return {
        ...state,
        cartItems: increasedCart,
      };

    case 'DECREMENT_QUANTITY':
      const decreasedCart = state.cartItems.map((item) => {
        if (item.id === action.payload.id) {
          const newQuantity = item.quantity - 1;
          return { ...item, quantity: newQuantity >= 1 ? newQuantity : 1 };
        }
        return item;
      });
      localStorage.setItem('cartItems', JSON.stringify(decreasedCart));
      return {
        ...state,
        cartItems: decreasedCart,
      };

    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  const [cartState, cartDispatch] = useReducer(cartReducer, { cartItems: storedCartItems });

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartState.cartItems));
  }, [cartState.cartItems]);

  const calculateTotal = () => {
    const total = cartState.cartItems.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0);
    return total;
  };

  return (
    <CartContext.Provider value={{ cartState, cartDispatch, calculateTotal }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
