
import React, { useEffect,useState, useRef } from 'react';
import { useCart } from '../CartContext ';
import './Cart.css';
import images from '../../Images/Egg.jpg'
import emptycart from '../../Images/ecart.gif'
import axios from 'axios';
import { useFoodCart } from '../FoodContext';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
// import Razorpay from 'razorpay';
import useRazorpay from 'react-razorpay';
import { globalapi } from '../../GlobalApi';
import { Link } from 'react-router-dom';



const Cart = () => {
  const [cust_phone, setcustphone] = useState('');
  const [cust_name, setcustname] = useState('');
  const [cust_discount, setcustdiscount] = useState('');

  const { state: foodCartState, dispatch: foodCartDispatch } = useFoodCart(); // Extract the dispatch function
  const { cartState, cartDispatch } = useCart();
  const [cartEmpty, setCartEmpty] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);


  const isUnmounted = useRef(false); // Flag to track if the component is unmounted

useEffect(() => {
  return () => {
    isUnmounted.current = true; // Set the flag to true when the component unmounts

    // Remove data from local storage
    // localStorage.removeItem('foodCart');
    // localStorage.removeItem('cartItems');
  };
}, []); // Empty dependency array to run only once on component mount


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  const handleIncrement = (item) => {
    const newQuantity = item.quantity + 1;

if (item.available_room !== undefined) {
  const parsedAvailableRoom = parseInt(item.available_room, 10);

  if (!isNaN(parsedAvailableRoom) && newQuantity > parsedAvailableRoom) {
    // Display an alert message when incrementing exceeds available quantity
    toast.warn('No More Rooms Available');
    return;
  }
}

    cartDispatch({ type: 'INCREMENT_QUANTITY', payload: item });
  };

  const handleDecrement = (item) => {
    cartDispatch({ type: 'DECREMENT_QUANTITY', payload: item });
  };

  const handleRemove = (item) => {
    cartDispatch({ type: 'REMOVE_FROM_CART', payload: item });
  };

// Food Dispatcher Starts Hear

  const handleFoodIncrement = (item) => {
    foodCartDispatch({ type: 'INCREMENT_FOOD_QUANTITY', payload: item });
  };
  
  const handleFoodDecrement = (item) => {
    foodCartDispatch({ type: 'DECREMENT_FOOD_QUANTITY', payload: item });
  };
  
  const handleFoodRemove = (item) => {
    foodCartDispatch({ type: 'REMOVE_FROM_FOOD_CART', payload: item });
  };

  // console.log('Cart State:', cartState);
  
  useEffect(() => {
    // Check if both carts are empty
    if (foodCartState.cart.length === 0 && cartState.cartItems.length === 0) {
      setCartEmpty(true); // If both carts are empty, set cartEmpty to true
    } else {
      setCartEmpty(false); // If at least one cart has items, set cartEmpty to false
    }
  }, [foodCartState.cart, cartState.cartItems]);


 const Roomavilable = cartState.cartItems
 const availableRooms = Roomavilable.length > 0 ? Roomavilable[0].available_room : 0;
//  console.log('Available Rooms:', availableRooms);


const calculateTotal = () => {
  const foodTotal = foodCartState.cart.reduce((acc, item) => {
    return acc + item.price * item.quantity;
  }, 0);
  const roomTotal = cartState.cartItems.reduce((acc, item) => {
    const itemTotal = (item.offered_price || item.price) * (item.quantity || 1);
    return acc + itemTotal;
  }, 0);

  return foodTotal + roomTotal;
};

 const calculateNetAmt = () => {
    const foodTotal = foodCartState.cart.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0);
    const roomTotal = cartState.cartItems.reduce((acc, item) => {
      const itemTotal = (item.offered_price || item.price) * (item.quantity || 1);
      return acc + itemTotal;
    }, 0);

    return foodTotal + roomTotal-cust_discount;
  };

  const gstchecker = () => {
    const totalWithoutGST = calculateTotal(); // Calculate the total without GST
    const gst = (18 / 100) * totalWithoutGST; // Calculate the GST amount
    return gst; // Return the total including GST
  };

// End Hear


const userData = JSON.parse(localStorage.getItem('userData'));


const handleCheckout = async () => {
  if (!cust_phone) {
    toast.warn('Please enter customer Phone');
    return;
  }
  if (!cust_name) {
    toast.warn('Please enter customer Name');
    return;
  }
  if (!isChecked) {
    toast.warn('Please accept blow terms and condition');
    return;
  }

  const checkoutData = {
    roomCart: cartState.cartItems,
    foodCart: foodCartState.cart,
    userdata: userData,
    total: calculateTotal(),
    cust_phone:  cust_phone,
    cust_name:cust_name,
    cust_discount:cust_discount,
  };
  console.log('Checkout Data:', checkoutData);

  try {
    await axios.post(globalapi+'doOrder_room', checkoutData);
  
    // Remove data from local storage
    localStorage.removeItem('foodCart');
    localStorage.removeItem('cartItems');
    setCartEmpty(true); // Set cartEmpty to true to empty the cart

    toast('📦 Order Confirmed Successfully', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

    // Navigate to '/' route after a 2-second delay

    window.location.href = '/orders';


    setForceRerender((prev) => !prev); // Force re-render to update the cart
  } catch (error) {
    console.error(error);
    toast.error('Error placing the order. Please try again later.');
  }
};

// Payemnt Enviourment by Rezorpay

// const [Razorpay] = useRazorpay();

const handelpayment = async (calculateTotal) =>{
  const _data = {calculateTotal: calculateTotal}
  axios.post(`https://www.example razorpay api.com`,_data)
  .then(res =>{
   console.log(res.data,"razorpay")
   hendelopenrezorpay(res.data)
  }).catch(err =>{
   console.log("payment error")
  })
 }
 

const hendelopenrezorpay = (data) =>{
  const options = {
    key: 'rzp_test_o6C4vPFbFWFYZP', // Enter the Key ID generated from the Dashboard
    amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: "Nature4",
    description: "Nature4 is an Resort chain company",
    image: "https://example.com/your_logo",
    order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
    handler: function (response) {
      alert(response.razorpay_payment_id);
      alert(response.razorpay_order_id);
      alert(response.razorpay_signature);
    },
    prefill: {
      name: "Piyush Garg",
      email: "youremail@example.com",
      contact: "9999999999",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  const rzp1 = new window.Razorpay(options);
  
  rzp1.on("payment.failed", function (response) {
    alert(response.error.code);
    alert(response.error.description);
    alert(response.error.source);
    alert(response.error.step);
    alert(response.error.reason);
    alert(response.error.metadata.order_id);
    alert(response.error.metadata.payment_id);
  });
  rzp1.open();
  
 
}




  return (
    <>
{cartEmpty ? (
        <div className='emptycarts'>
        <div className="empty2">
           <img src={emptycart} alt="" />
          <h3>Cart is empty !</h3>
        </div>
        </div>
      ) : (
        <div className="fullcart">
<div className="productspage">
<div className="itemspage">
{
  foodCartState.cart.map((item,index)=>{
    return(
      <div className="product" key={index}>
<div className="imgtxt">
<div className='imgdivesimg'>
<img src={item.image} alt="" />
</div>
<div className="imgdives">
<h4>{item.room_type || item.name}</h4>
<p>₹{item.price} /Room</p>
</div>
</div>
<div className="quantitydiv">
<button onClick={() => handleFoodDecrement(item)}>-</button>
     <p>{item.quantity}</p>
<button onClick={() => handleFoodIncrement(item)}>+</button>

</div>
<div className="removeiteam">
  <button onClick={() => handleFoodRemove(item)}><i class="fa-solid fa-xmark"></i></button>
</div>
</div>
    )
  })
}

{/* Room Data Cart */}
{
  cartState.cartItems.map((item,index)=>{
    const isMaxReached = item.quantity >= parseInt(item.available_room, 10);
    return(
      <div className="product" key={index}>
<div className="imgtxt">
<div className='imgdivesimg'>
<img src={item.room_image} alt="" />
</div>
<div className="imgdives">
<h4>{item.room_type || item.name}</h4>
<p> ₹{item.offered_price || item.price}</p>
{/* <p>{item.available_room}</p> */}
</div>
</div>
<div className="quantitydiv">
  <button   onClick={() => handleDecrement(item)}>-</button>
     <p>{item.quantity}</p>
<button onClick={() => handleIncrement(item)}>+</button>
</div>
<div className="removeiteam">
  <button onClick={() => handleRemove(item)}><i class="fa-solid fa-xmark"></i></button>
</div>
</div>
    )
  })
}     
</div>
</div>
{/* Payment Div Side */}
<div className="paymentpage">
  {/* <h4>Payment Info</h4> */}
  <div className="payt">
    {/* <div className="paymentoption">
    <div className="coddiv">COD <i class="fa-solid fa-truck"></i></div>
    <div className="onlinediv">UPI/Card  <i class="fa-brands fa-cc-visa"></i></div>
    </div> */}

<div className="itemscount">
  <div className="itemsname">
<p>iteam ({foodCartState.cart.length+cartState.cartItems.length})</p>
  </div>
  <div className="iteamprice">
 <p>₹{calculateTotal()}/-</p>
  </div>
</div>
{/* Delivery Div Start Hear */}

    {/* <div className="deliverychargs">

    <div className="catdelivery">
    <p className='cats'>Delivery Charges</p>
    </div>
    <div className="catprice">
      <p className='itmprc'>--</p>
    </div>
    </div> */}



{/* GST Div Start Hear */}

    <div className="GST">
    <div className="gstname">
      <p className='cats'>Discount</p>
    </div>
    <div className="gstprice">
      <p className='itmprc'>
      <input
      type="number" placeholder="₹0" 
      value={cust_discount}
      onChange={(e) => setcustdiscount(e.target.value)}                 
    />


      </p>
    </div>
    </div>
{/* Total; count div start Hear */}
<div className="totaldivss">
<div className="totalwright">
  <p>Total Net Price</p>
</div>
<div className="totalprices">
<p>₹{calculateNetAmt()}/-</p>
</div>
</div>


<div class="showcenter">
<tr> <b>Enter Customer Detail:</b></tr>
<tr>     <input
      type="text" placeholder="Customer Phone" 
      value={cust_phone}
      onChange={(e) => setcustphone(e.target.value)} required                
    /></tr>
<tr> <input
      type="text" placeholder="Customer Name" 
      value={cust_name}
      onChange={(e) => setcustname(e.target.value)} required                
    /></tr>
</div>



{/* handleCheckout */}
<div className="paymentbutton">
  <button onClick={ handleCheckout}>Confirm Order <i class="fa-solid fa-receipt"></i></button>
</div>

<div className="termscondition">
<input
          type="checkbox"
          id="agreeCheckbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
         <label htmlFor="agreeCheckbox">I accept the <Link to={'/termsandcondition'} style={{color:'blue'}}><u>terms & condition</u> </Link></label>

</div>

  </div>
</div>
</div>
      )}

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
{/* Same as */}
<ToastContainer />



</>





  );
};

export default Cart;
