import React from 'react'
import '../Footer/Footer.css'
import logo from '../../Images/Logoimage.png'
import {Link} from 'react-router-dom'
const Footer = () => {
  return (
    <>
    <div className="footermain">


    <div className="personalinfo">
    <div className="personalinfoone">
          <img src={logo} alt="" />
          <h4>Nature4 </h4>
        </div>
    {/* <div className="personalinfotwo">
      <p>Eco Resort Purulia</p>
    </div> */}
    </div>

    {/* Support Section */}

    <div className="suppors">
      <h5>Support</h5>
    <Link>FAQ's</Link>
    <Link>Terms & Conditions</Link>
    <Link>Privecy Policy</Link>
    <Link>Contract</Link>
    </div>
    {/* Phone & Email */}
       <div className="phoneemail">
       <div className="one">
       <h3>Phone</h3>
       <a href="tel:+919875686160"><i class="fa-solid fa-phone"></i>+91 98756 86160</a>
       </div>
       <div className="two">
       <h3>Email</h3>
        <a href="https://mail.google.com/mail/?view=cm&to=Purulianature4@gmail.com&su=Subject%20Here&body=Body%20Text%20Here"><i class="fa-solid fa-envelope"></i>Purulianature4@gmail.com</a>
       </div>
       </div>

       {/* Address Part Start Hear */}

       <div className="addresspart">
       <div className="addess">
       <h3>Address</h3>
       <a href="https://www.google.com/maps/search/?api=1&query=HILL%20TOP,%20AJODHYA,%20Baghmundi,%20West%20Bengal%20723152,%20Baghmundi,%20India,%20West%20Bengal">
    <p><i class="fa-solid fa-location-dot"></i> Hill Top, Ajodhya, Baghmundi, West Bengal 723152</p>
</a>
       </div>
       <div className="followus">
      <h3>Follow Us </h3>
      <div className="socialmediadiv">
      <a href="https://www.facebook.com/profile.php?id=61552798707335"><i class="fa-brands fa-facebook"></i></a>
      <a href="https://www.instagram.com/nature4resort/"><i class="fa-brands fa-square-instagram"></i></a>
      <a href=""><i class="fa-brands fa-x-twitter"></i></a>
      <a href=""><i class="fa-brands fa-youtube"></i></a>
      </div>
       </div>
       </div>
    </div>
    </>
  )
}

export default Footer