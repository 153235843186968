import React, { useEffect, useState } from 'react';
import '../Order/Order.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { globalapi } from '../../GlobalApi';
import { red } from '@mui/material/colors';
import { colors } from '@mui/material';

const Order = () => {
  const [activeTab, setActiveTab] = useState('Ongoing');
  const [ongoingorder, setOngoingOrder] = useState([]);
  const [onbording, setonbording] = useState(true);
  const [foodorder, setfoodorder] = useState([])

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));

    // this for users Current Order Data
    if (userData && userData.userid) {
      axios
        .post(globalapi+'getongoingjobs', {
          userid: userData.userid,
        })
        .then((res) => {
          const myorders = res.data
          setfoodorder(myorders)
          const ongoingorderse = myorders.ongoingjobs
          setOngoingOrder(ongoingorderse);
        })
        .catch((error) => {
          console.error('Error fetching ongoing jobs:', error);
        });
    }
  }, []);

// This is For Order History !
console.log("orderdata",ongoingorder)


const [Orderhistory, setOrderhistory] = useState([])

useEffect(()=>{
  const userData = JSON.parse(localStorage.getItem('userData'));
  if (userData && userData.userid) {
        axios.post(globalapi+'gethistoryjobs',{
          userid: userData.userid,
        }).then((res)=>{
            setOrderhistory(res.data)
        }).catch((err)=>{
           console.warn(err)
        })
  }
},[])

console.log(Orderhistory)


// End Hear

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // Call the function that needs to be executed when the tab is clicked
  };

  const totalreturn = () => {
    setonbording(!onbording);
  };

  // Function to call both totalreturn and handleTabClick
  const handleTabClickAndTotalReturn = (tab) => {
    handleTabClick(tab);
    totalreturn();
  };

  return (
    <>
      <div className="mainorder">
        <div className="chooseorder">
          <ul className="chooseul">
            <li
              className={activeTab === 'Ongoing' ? 'active' : ''}
              onClick={() => handleTabClickAndTotalReturn('Ongoing')}
            >
              Ongoing
            </li>
            <li
              className={activeTab === 'History' ? 'active' : ''}
              onClick={() => handleTabClickAndTotalReturn('History')}
            >
              History
            </li>
          </ul>
        </div>

        {/* Order div start here */}

        {/* <div class="timeline-block timeline-block-left">
    <div class="marker active">
    <i aria-hidden="true" class="fa fa-check active"></i>
</div>

</div> */}
        <div className="orders">
          <div className="scrollabeldiv">

  {onbording ? (
    <>

    {ongoingorder.length === 0 ? (
      <div className="his"><h2>Order Status Empty !</h2></div>
) : (


  ongoingorder.map((orders, index) => {
    const isCat33 = orders.cat_id === "33";
    const orderNumber = orders.order_num;
    return (
      <>

<div className="card" key={index}>

              <tr ><b>{orders.order_display_note} ,Ord#{orders.order_num}</b></tr>
              <tr >Customer:{orders.cust_name} &nbsp; <a href="tel:{orders.cust_phone}">
              <i class="fa-solid fa-phone"></i>
                 {orders.cust_phone}</a></tr>

                   
              {isCat33 ? null : (
                <tr >
                 <b>Check-In:</b> &nbsp;{orders.order_est_sdttm || null} <b>Check-Out:</b>&nbsp; {orders.order_est_edttm || null}
                </tr>
              )}

             {isCat33 ? (
                <tr >Total Quantity: {orders.total_qty}</tr>
              ) : (
                <tr ><b>Total Rooms:</b>&nbsp; {orders.total_qty} for {orders.note_value} Nights</tr>
              )}

              <tr >
              <b> Total Price:&nbsp;</b>₹{orders.parts_price}   <b>{orders.status}</b>
              </tr>     

              <tr >
              <b> Booking Agent:&nbsp;</b> {orders.booking_agent}
              </tr>        
                       
                
                
        

          
          {/* <div >
            <button class="red">Cancel</button>
          </div>
          */}

          <Link to={`/ordersdetails/${orderNumber}`}>
          <div >
            <button class="green">Details</button>
          </div>
          </Link>
         

        </div>
      
        {/* <div className="myorder" key={index}>
          <div className="texts">
            <div className="textbasics">
              <h3 className="componame">{orders.order_display_note}</h3>
             {isCat33 ? (
                <p className="count">Total Quantity: {orders.total_qty}</p>
              ) : (
                <p className="count">Total Rooms: {orders.total_qty}</p>
              )}
              {isCat33 ? null : (
                <p className="date">
                  {orders.order_est_sdttm || null} to {orders.order_est_edttm || null}
                </p>
              )}
              <p className="price">₹{orders.parts_price} </p>
            </div>
            <div className="statustext">
              <div className="statusorder">
                <p className="ordernum">Order : {orders.order_num} </p>
                <p className="approved">{orders.status}</p>
                
               
              </div>
            </div>
          </div>
          <Link to={`/ordersdetails/${orderNumber}`}>
          <div className="buttoncanceld">
            <button>Details</button>
          </div>
          </Link>

        </div> */}


      </>
    );
  })


  
)}

 

    </>
  
) : (
  <div className="his"><h2>Order History Empty !</h2></div>
)}


          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
