import React from 'react'
import '../Routepath/Routepath.css'
import { Link } from 'react-router-dom';
const Rotepath = () => {
 

    const Footercontent = [
  {
    id: 0,
    icon: <i class="fa-solid fa-bell-concierge"></i>,
    routename: "Room Booking",
    path: '/roombooking'
  },
  {
    id: 1,
    icon: <i class="fa-solid fa-cookie-bite"></i>,
    routename: "Food Booking",
    path: '/Foodbooking'
  },
  {
    id: 2,
    icon: <i class="fa-solid fa-car-side"></i>,
    routename: "Cab Booking",
    path: '/cabbooking'
  }
  // Add more objects as needed
];
  return (
    <>
    <div className="routefooter">
{
    Footercontent.map((foot,index)=>{
        return(
        <>
  
    <div className="FooterRoute">
    <Link to={foot.path}>
      <p>{foot.icon}</p>
      <h4>{foot.routename}</h4>

    </Link>
     </div>
        </>
   
        )
    })
}

    


    </div>
    </>
  )
}

export default Rotepath