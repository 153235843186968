import React, { useState, useEffect } from 'react';
import '../TabilBooking/TabilBooking.css';
import Tabil1 from '../../Images/Tabilroom.jpg';
import Food1 from '../../Images/Food1.jpg'
import Food2 from '../../Images/Food2.jpg'
import Food3 from '../../Images/Food3.jpg'
import Food4 from '../../Images/Food4.jpg'
import Food5 from '../../Images/Food5.jpg'

const TabilBooking = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [Tabil1, Food1,Food2,Food3,Food4,Food5]; // Add more image paths as needed

  useEffect(() => {
    const interval = setInterval(() => {
      const nextImageIndex = (currentImageIndex + 1) % images.length;
      setCurrentImageIndex(nextImageIndex);
    }, 6000); // Change image every 4 seconds (4000 milliseconds)
    return () => clearInterval(interval);
  }, [currentImageIndex]);





  // Tabil booking form data
  const [formData, setFormData] = useState({
    datetime: '',
    persons: '',
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    selectedTime: '', // Add selectedTime property
  });

  const [formErrors, setFormErrors] = useState({});

  const availableTimes = ["9:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "1:00 PM"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTimeChange = (time) => {
    setFormData({ ...formData, selectedTime: time });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form validation and submission logic (as shown in the previous responses)
  };

  

  return (
    <>
      <div className="mainTabil">


  
        <div className="tabilroom">
          <div className="tabildetails">
            <img src={images[currentImageIndex]} alt="" />
            
          </div>
          <div className="details">
                <h4><i class="fa-solid fa-location-dot"></i>  Purulia, West Bnagal - 715485</h4>
                <p><i class="fa-sharp fa-solid fa-coins"></i> Approxymetly ₹700 for Two</p>
                <p ><i class="fa-regular fa-clock"></i> <span className='clockp'>Open from</span> 01:30 PM - 03:30 AM</p>
            </div>

            <div className="disclamber">
              <h4><i class="fa-solid fa-wifi"></i>  Free Wifi</h4>
              <h4><i class="fa-solid fa-car"></i>  Free Parking</h4>
              <h4><i class="fa-solid fa-leaf"></i>  100% Natural</h4>
              <h4><i class="fa-solid fa-bowl-food"></i>  100% Fresh</h4>
            </div>

        </div>
     





        <div className="tabilbooking">



        <form onSubmit={handleSubmit}>
      <h3 className='headertext'>Book a Table</h3>
      <div className="namegroup">
        <input
          type="date"
          name="datetime"
          className='dateinput'
          value={formData.datetime}
          onChange={handleInputChange}
          required
        />
        <select
          name="persons"
          className='personselect'
          value={formData.persons}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Number of Persons</option>
          <option value="1">1 Person</option>
          <option value="2">2 Persons</option>
          <option value="3">3 Persons</option>
          <option value="4">4 Persons</option>
          <option value="5">5 Persons</option>
        </select>
      </div>
      <div className="mailid">
        <input
          type="email"
          name="email"
          placeholder='  john@example.com'
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        {formErrors.email && <span className="error-message">{formErrors.email}</span>}
      </div>
      <div className="namedetails">
        <input
          type="text"
          name="firstname"
          className='firstname'
          placeholder='   First Name'
          value={formData.firstname}
          onChange={handleInputChange}
          required
        />
        {formErrors.firstname && <span className="error-message">{formErrors.firstname}</span>}
        <input
          type="text"
          name="lastname"
          className='lastname'
          placeholder='   Last Name'
          value={formData.lastname}
          onChange={handleInputChange}
          required
        />
        {formErrors.lastname && <span className="error-message">{formErrors.lastname}</span>}
      </div>
      <div className="phonenumber">
        <input
          type="tel"
          name="phone"
          className='numbercheck'
          placeholder='  Mobile Number'
          value={formData.phone}
          onChange={handleInputChange}
          required
        />
        {formErrors.phone && <span className="error-message">{formErrors.phone}</span>}
      </div>
      <div className="time-selector">
        <div className="time-slides-container">
          <div className="time-slides">
          {availableTimes.map((time, index) => (
              <label key={index} className={`time-slide ${time === formData.selectedTime ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="time"
                  value={time}
                  checked={time === formData.selectedTime}
                  onChange={() => handleTimeChange(time)}
                />
                {time}
              </label>
            ))}
          </div>
        </div>
        {formErrors.selectedTime && <span className="error-message">{formErrors.selectedTime}</span>}
      </div>
      <div className="btndiv">
        <button type="submit">Book Now</button>
      </div>
    </form>
        </div>
      </div>
    </>
  );
};

export default TabilBooking;
