import { Route, Routes } from 'react-router-dom';
import Banner from './Pages/Banner/Banner';
import RoomBooking from './Pages/RoomBooking/RoomBooking';
import TabilBooking from './Pages/TabilBooking/TabilBooking';
import Cart from './Pages/Cart/Cart';
import { CartProvider } from './Pages/CartContext '; // Import your CartContext
// import Navbar from './Pages/Header/Navbar';
import Foodmenu from './Pages/FoodPage/Foodmenu';
import { FoodCartProvider } from './Pages/FoodContext';
import Payment from './Pages/Payment/Payment';
import Login from './Pages/Login/Login';
import Signup from './Pages/Signup/Signup';
import Footer from './Pages/Footer/Footer';
import Profile from './Pages/Profile/Profile';
import Privetroute from './Pages/Privetroute';
import Nav from './Pages/Navbar/Nav';
import Order from './Pages/Order/Order';
import Email from './Pages/Emailsub/Email';
import Rotepath from './Pages/Routepath/Rotepath';
import OrderDetails from './Pages/OrderDetails/OrderDetails';
import Bottomfooter from './Pages/Bottomfooter/Bottomfooter';
import TandS from './Pages/Termsandcondition/TandS';
//import Cabmenu from './Pages/CabPage/Cabmenu';
import Cabmenu from './Pages/CabPage/Cabmenu';

function App() {
  
  return (
    <>
    <CartProvider>
    <FoodCartProvider>
    <Nav/>
     <Routes>
     <Route path='/' element={<Banner/>}/>
     <Route path='/tabilbooking' element={<TabilBooking/>}/>
     <Route path='/roombooking' element={<RoomBooking/>}/>
     <Route path='/Foodbooking' element={<Foodmenu/>}/>
     {/* <Route path='/cabbooking' element={<Cabmenu/>}/> */}
     <Route path='/payment' element={<Payment/>}/>
     <Route path='/login' element={<Login/>}/>
     <Route path='/signup' element={<Signup/>}/>
     <Route path='/termsandcondition' element={<TandS/>}/>


     <Route to={'/auth'} element={<Privetroute/>}>
     <Route path='profile' element={<Profile/>}/>
     <Route path='cart' element={<Cart/>}/>
     <Route path='orders' element={<Order/>}/>
     <Route path='/ordersdetails/:ordernumber' element={<OrderDetails/>}/>
     </Route>
     </Routes>
    <Rotepath/>
    {/* <Email/> */}
     <Footer/>
     <Bottomfooter/>
    </FoodCartProvider>
    </CartProvider>
    
   {/* <OrderDetails/> */}
    </>
  );
}

export default App;
