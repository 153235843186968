import React, { useState, useEffect } from 'react';
import '../RoomBooking/RoomBook.css';
import Hotelroom from '../../Images/36.png'
import axios from 'axios';
import { useCart } from '../CartContext ';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { globalapi } from '../../GlobalApi';

const RoomBooking = () => {

  const [HotelRoom, setHotelRoom] = useState([]) //Hotelroom Array Data 
  const [fainallroomdata, setfainallroomdata] = useState([])
  const [hotel, setHotel] = useState([]); //main Data Array
  const [startFromdata, setStartFrom] = useState(''); // State variable for Startfrom
  const [endFormdate, setendFormdate] = useState('') // state variable EndFrom
  const [formData, setFormData] = useState({
    checkin: '',
    checkout: '',
    adult: '2',
    child: '0',
    daysDifference: 0,
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  console.log(fainallroomdata)

  const calculateDaysDifference = () => {
    const checkinDate = new Date(formData.checkin);
    const checkoutDate = new Date(formData.checkout);

    if (checkinDate > checkoutDate) {
      alert('Check-out date must be after check-in date');
    } else {
      const timeDifference = checkoutDate - checkinDate;
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      setFormData({
        ...formData,
        daysDifference: daysDifference,
      });
    }
  };

  useEffect(() => {
    calculateDaysDifference();
  }, [formData.checkin, formData.checkout]);



  useEffect(() => {
    const storedRoomData = sessionStorage.getItem('selectRooms');
    if (storedRoomData) {
      const parsedData = JSON.parse(storedRoomData);
      setfainallroomdata(parsedData);
    }

    // Other initializations or data fetching logic can be added here
  }, []); // Empty dependency array ensures this runs only once on mount








useEffect(()=>{
 const startdateofroom = sessionStorage.getItem('startdate')
 if(startdateofroom){
  const startdateroom = JSON.parse(startdateofroom)
  setStartFrom(startdateroom);
 }
},[])

useEffect(()=>{
 const enddateofroom = sessionStorage.getItem('Enddate')
 if(enddateofroom){
  const enddate = JSON.parse(enddateofroom)
  setendFormdate(enddate)
 }
},[])

// console.log(hotel)

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(globalapi+`chk_available_room&company_id=19269&subcatid1=1&cat_id=29&adult=${formData.adult}&child=${formData.child}&noofdays=${formData.daysDifference}&startdate=${formData.checkin}&enddate=${formData.checkout}&fexiable=1`)
      .then((res) => {
        const Totaldata = res.data;
        console.log(Totaldata)
        const Startfrom = Totaldata.startdate;
        const EndFrom = Totaldata.enddate
        const RoomData = Totaldata.room_inv
        // console.log("roomdata",RoomData)
      window.location.href = '/roombooking';

        sessionStorage.setItem('selectRooms', JSON.stringify(RoomData));
        sessionStorage.setItem('startdate', JSON.stringify(Startfrom));
        sessionStorage.setItem('Enddate', JSON.stringify(EndFrom));
        setHotel(Totaldata);

      })
      .catch((err) => {
        console.warn('API Request Error:', err);
      });
  };
  console.log("hoteldata",hotel)



const [firstrenderdata, setfirstrenderdata] = useState([])


useEffect(()=>{
  
  axios.get(globalapi+'show_all_room&company_id=19269&subcatid1=1&cat_id=29').then((res)=>{
  const freeroom = res.data
  const freefainalroom = freeroom.room_list
  setfirstrenderdata(freefainalroom)
 }).catch((err)=>{
  console.warn(err)
 })
},[])
// console.log(firstrenderdata)



const { cartDispatch } = useCart(); // Get cartDispatch from CartContext

// ...other RoomBooking component code

const addToCart = (item) => {
  cartDispatch({ type: 'ADD_TO_CART', payload: item });
  toast.success('Record Added To Cart Successfully');
  //window.location.href = '/roombooking';
};


const emptyroom = () =>{
 
  toast.warn('Select Check-in and Check-out Date.');
  
  
}




  return (
    <>
      
    <div className="maindivbook">

      <div className="bookingdiv">
        <form onSubmit={handleSubmit}>

        <div className="oneDive">

          <div className='checkindiv'>
            <p>Check-in</p>
            <input
              type="date"
              name="checkin"
              value={formData.checkin}
              onChange={handleChange}
              required
            />
          </div>

          <div className='checkoutdiv'>
            <p>Check-Out</p>
            <input
              type="date"
              name="checkout"
              value={formData.checkout}
              onChange={handleChange}
              required
            />
          </div>

          <div className='persondiv'>
            <p>Adult</p>
            <select
              name="adult"
              value={formData.adult}
              onChange={handleChange}
            >
              <option value="1">1 person</option>
              <option value="2">2 persons</option>
              <option value="3">3 persons</option>
              <option value="4">4 persons</option>
              <option value="5">5 persons</option>
              <option value="6">6 persons</option>
              <option value="7">7 persons</option>
              <option value="8">8 persons</option>
              <option value="9">9 persons</option>
              <option value="10">10 persons</option>
            </select>
          </div>

        </div>




<div className="twoDive">


          <div className='childdiv'>
            <p>Child</p>
            <select
              name="child"
              value={formData.child}
              onChange={handleChange}
            >
              <option value="0">0 child</option>
              <option value="1">1 child</option>
              <option value="2">2 children</option>
              <option value="3">3 children</option>
              <option value="4">4 children</option>
              <option value="5">5 children</option>
              <option value="6">6 children</option>
             
            </select>
          </div>
     
          <div className="searchbutton">
            <button type="submit">Search</button>
          </div>
</div>


        </form>
      </div>
    </div>


{/* Result div starting hear */}
<div className="resultdiv">
  <div className="resulthotel">


    <h4>Total Result  {HotelRoom.length || fainallroomdata.length}</h4>

     { fainallroomdata &&fainallroomdata.length === 0 ? (
      <>

 {
  firstrenderdata.map((freroom,index)=>{
      return(
        <>

      
      <div className="hotelroomdiv" key={freroom.room_type_id}>
      <div className="hotelroomimg">
      <img src={freroom.room_image} alt="" />
      </div>
      <div className="hotelroomdetails">
        <h3>{freroom.room_type}</h3>
        {/* <p className='date'><span className='datespain'>{startFromdata}</span>  <i class="fa-solid fa-right-left"></i>  <span className='datespain'>{endFormdate}</span> </p> */}
        <p className='facilt1'>{freroom.amen6_name}</p>
        <p className='facilt'>        
        Facility : {freroom.amen1_name} - {freroom.amen2_name} - {freroom.amen3_name} - {freroom.amen4_name} <i class="fa-solid fa-circle-info"></i></p>
<div className="hotelprice">
<p className='priviousprice'><del>₹{freroom.original_price}</del></p>
<p className='hotelpric'>₹{freroom.offered_price}</p>
<p> Per Night</p>
</div>


      </div>
     <div className="hotelroombooking">
      <button onClick={emptyroom}>Add To Cart</button>
      
     </div>
     </div>
        </>
      )
  })
 }
 
 
      </>
     ):(
      fainallroomdata.map((roomdata,index)=>{
        const isRoomAvailable = roomdata.available_room > 0;
    return(
       <div className="hotelroomdiv" key={roomdata.room_type_id}>

      <div className="hotelroomimg">
      <img src={roomdata.room_image} alt="" />

      </div>

      <div className="hotelroomdetails">
        <h3>{roomdata.room_type}</h3>
        <p className='date'><span className='datespain'>{startFromdata}</span>  <i class="fa-solid fa-right-left"></i>  <span className='datespain'>{endFormdate}</span> &nbsp; &nbsp;Room Left <span style={{color:'green'}}>{roomdata.available_room}</span> </p>
        
        <p className='facilt'>
        Facility : {roomdata.amen1_name} - {roomdata.amen2_name} - {roomdata.amen3_name} -  {roomdata.amen4_name}</p>

<div className="hotelprice">
<p className='priviousprice'><del>₹{roomdata.original_price}</del></p>
<p className='hotelpric'>₹{roomdata.offered_price} </p>

</div>

<small> &nbsp; &nbsp;for {roomdata.note}</small>





      </div>




      
     <div className="hotelroombooking">
     {isRoomAvailable ? (
          <button onClick={() => addToCart(roomdata)}>Add To Cart</button>
        ) : (
          <button disabled style={{backgroundColor:'gray'}}>Out of stock</button>
        )}
      
     </div>
     </div>
    )
  })
     )
     }

     

   

     <ToastContainer/>

</div>
</div>

<div className="osse">

</div>

    </>

  );
};

export default RoomBooking;
