import React, { useEffect, useState } from 'react'
import '../Termsandcondition/TandS.css'
import axios from 'axios'
const TandS = () => {
  const [terms, setterms] = useState([])
  useEffect(()=>{
   axios.get('https://bsoapp.in/servicefinder/api/dev/api_sumon.php?action=gettermsinfo4').then((res)=>{
   const totalterm = res.data
   const Terms = totalterm.terms_hdr
    setterms(Terms)
   }).catch((err)=>{
    console.warn(err)
   })
  },[])
  console.log(terms)
  return (
    <div className='terms'>
    <h2 style={{color:'green',marginTop:'10px'}}>Terms & Condition of Nature4 Resort</h2>
       {
        terms.map((tem,index)=>{
         return(
          <p className={tem.linetype === 'h' ? 'header' : 'discription'} key={index}>
            {tem.hdr_desc}
          </p>
         )
        })
       }
    </div>
  )
}

export default TandS