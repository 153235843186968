import React, { useEffect ,useState} from 'react'
import axios from 'axios'
const Payment = () => {
    const [catagory, setcatagory] = useState([])
    useEffect(()=>{
      axios.get('https://bsoapp.in/servicefinder/api/dev/api_sumon.php?action=getSubcatinfo&scid=21679&cat_id=33&subcat_level=1&company_id=1').then((res)=>{
        setcatagory(res.data)
      }).catch((err)=>{
        console.warn(err)
      })
    },[])
    console.log(catagory)
  return (
    <div>Payment</div>
  )
}

export default Payment