import React,{useState} from 'react'
import '../Signup/Signup.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { globalapi } from '../../GlobalApi';

const Signup = () => {

  const navigate = useNavigate()

  const [submitedata, setsubmitedata] = useState([])
  const [message, setMessage] = useState(null);

  const [formData, setFormData] = useState({
    company_id: null,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    gender: '',
    passwd: '',
    confirmPassword: '',
    address: '',
    pin: '',
    city: '',
    state: null

  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if password and confirmPassword match
    if (formData.passwd !== formData.confirmPassword) {
      // Show an alert message
      alert("Passwords don't match");
      return; // Prevent further execution of the code
    }

    axios
      .post(
        globalapi+`signupUser`,
        formData
      )
      .then((res) => {
        setsubmitedata(res.data);

        console.log('Response:', res.data); // Check the actual response

        // Check if the response status is "successful"
        if (res.data.status === 'successfull') {
          // localStorage.setItem('UserInfo', JSON.stringify(res.data.user));
          setTimeout(() => {
            navigate('/login'); // Navigate to '/login' after 2 seconds if status is 'successful'
          }, 2000);
        } else {
          console.log('Status is not successful'); // Log if status doesn't match
        }

        console.log(submitedata);
      })
      .catch((err) => {
        console.warn(err);
      });
  };





  return (
    
    <>
      <div className="fullscreensignup">
        <div className="contentsignup">

        <h3>Register A New User</h3>


          <form onSubmit={handleSubmit}>


     <div className="namesection">
      <input type="text" placeholder='Name'      id="first_name"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange} required/>

      {/* <input type="text" placeholder='Last name'  id="last_name"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange} /> */}
     </div>

<div className="phonegender">
  <input type="number" placeholder='Phone Number'  id="phonenumber"
          name="phonenumber"
          value={formData.phonenumber}
          onChange={handleChange} required/>
          
          {/* <select
          id="gender"
          name="gender"
          value={formData.gender}
          onChange={handleChange}
           >
          <option value="">Select</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select> */}
</div>

     {/* <div className="contractdiv">
      <input type="email" placeholder='Email'
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange} />
     </div> */}

     <div className="passwordsss">
      <input type="password" placeholder='Password'  id="passwd"
          name="passwd"
          value={formData.passwd}
          onChange={handleChange} required/>
      <input type="password" placeholder='Confirm Password'   id="confirmPassword"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange} required/>
     </div>
     {/* <div className="addresss">
      <input type="text" placeholder='city' id='city' name='city' value={formData.city} onChange={handleChange} />
      <input type="number" placeholder='Zip Code' id="pin"
          name="pin"
          value={formData.pin}
          onChange={handleChange} />
     </div> */}
      {/* <div className="address2">
        <input type="text" placeholder='Address'  id="address"
          name="address"
          value={formData.address}
          onChange={handleChange} />
      </div> */}
      <div className="butt">
        <button className='Submit button'>Register</button>
      </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Signup