import img1 from '../Fooddata/Foodimage/Biryani_2.jpeg'
import img2 from '../Fooddata/Foodimage/Burger.jpeg'
import img3 from '../Fooddata/Foodimage/Cakes.jpeg'
import img4 from '../Fooddata/Foodimage/Chinese.jpeg'
import img5 from '../Fooddata/Foodimage/Chole_Bature.jpeg'
import img6 from '../Fooddata/Foodimage/Desserts_2.jpeg'
import img7 from '../Fooddata/Foodimage/Dosa.jpeg'
import img8 from '../Fooddata/Foodimage/Gulab_Jamun.jpeg'
import img9 from '../Fooddata/Foodimage/Ice_Creams.jpeg'
import img10 from '../Fooddata/Foodimage/Kebabs.jpeg'
import img11 from '../Fooddata/Foodimage/Khichdi.jpeg'
import img12 from '../Fooddata/Foodimage/Noodles.jpeg'
import img13 from '../Fooddata/Foodimage/North_Indian_4.jpeg'
import img14 from '../Fooddata/Foodimage/Pastry.jpeg'
import img15 from '../Fooddata/Foodimage/Pizza.jpeg'

export const Fooddata = [
    {
        id: 0,
        image: img1,
        name: 'Beriyani'
    },
    {  id: 1,
        image: img2,
        name: 'Burger'
    },
    {  id: 2,
        image: img3,
        name: 'Cakes'
    },
    {  id: 3,
        image: img4,
        name: 'Chinecs'
    },
    {  id: 4,
        image: img5,
        name: 'Chole Bhature'
    },
    {  id: 5,
        image: img6,
        name: 'Desart'
    },
    {  id: 6,
        image: img7,
        name: 'Dhosha'
    },
    {  id: 7,
        image: img8,
        name: 'Julabjamoon'
    },
    {  id: 8,
        image: img9,
        name: 'Icecream'
    },
    {  id: 9,
        image: img10,
        name: 'Kababs'
    },
    {  id: 10,
        image: img11,
        name: 'Khichidi'
    },
    {  id: 11,
        image: img12,
        name: 'Noodles'
    },
    {  id: 12,
        image: img13,
        name: 'North-Indian'
    },
    {  id: 13,
        image: img14,
        name: 'Pastry'
    },
    {  id: 14,
        image: img15,
        name: 'Pizza'
    },
]