import React, { useEffect, useState } from 'react';
import '../Profile/Profile.css'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import {green } from '@mui/material/colors';
import { ToastContainer, toast } from 'react-toastify';


const Profile = () => {
  const [userData, setUserData] = useState([]);
  const [firstCharOfFirstName, setFirstCharOfFirstName] = useState('');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phonenumber: '',
    email: '',
    zipCode: '',
    password: '',
    city: '',
    address: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
  e.preventDefault();

  if (
    formData.first_name &&
    formData.last_name &&
    formData.phonenumber &&
    formData.email &&
    formData.zipCode &&
    formData.password &&
    formData.city &&
    formData.address
  ) {
    // Form is valid, update the userData in localStorage
    const updatedUserData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      phonenumber: formData.phonenumber,
      email: formData.email,
      zipCode: formData.zipCode,
      password: formData.password,
      city: formData.city,
      address: formData.address,
    };

    // Update userData in localStorage
    localStorage.setItem('userData', JSON.stringify(updatedUserData));

    window.location.href='/profile'

    // Display a success message or perform other actions
    console.log('Form submitted successfully:', formData);
  } else {
    toast.warn('Filling all data is mandatory');


  }
};



  useEffect(() => {
    // Fetch user data from localStorage when the component mounts
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      // If user data exists in localStorage, set it in state
      setUserData(JSON.parse(storedUserData));
    }
  }, []);
  useEffect(() => {
    if (userData && userData.first_name) {
      const firstCharCapitalized = userData.first_name.charAt(0).toUpperCase();
      setFirstCharOfFirstName(firstCharCapitalized);
    }
  }, [userData]);
  
  const handleLogout = () => {
    // Remove userData from localStorage
    localStorage.removeItem('userData');
    // Redirect to the home page
    window.location.href = '/';
  };


  return (
    <>
<div className="hero-container">


{/* Profile Card */}
<div className="profiledata">
<div className="profilecard">

<div className="profilecardone">
<Stack direction="row" spacing={2}>
<Avatar sx={{ bgcolor: green[500] }}>{firstCharOfFirstName}</Avatar>  
</Stack>
</div>
<div className="profilecardtwo">
<div>

  <h3>{userData.first_name} {userData.last_name}</h3>
  <p>+91 <span style={{color:'green'}}>{userData.phonenumber}</span></p>
</div>
</div>
<div className="profilecardthree">
  <button onClick={handleLogout}>Logout</button>
</div>


</div>
</div>



{/* Form Card */}



    <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
{/* Same as */}
<ToastContainer />
</div>
    </>
  )
}

export default Profile