import React, { useState, useEffect } from 'react';
import '../OrderDetails/OrderDetails.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { globalapi } from '../../GlobalApi';
import dataimage from '../../Images/Egg.jpg'




const steps = [
  {
    label: 'Pending',
  },
  {
    label: 'Order Confirmed',
  },
  {
    label: 'Order Successful',
  },
 
];

// Reject Status

const filteredSteps = [
  { label: 'Pending' },
  { label: 'Order Rejected' }
];

const OrderDetails = () => {
  const [activeStep, setActiveStep] = useState(0);
  
  const {ordernumber} = useParams()
  // console.log(ordernumber)
  //   steps [0 = pending , 1= confirmorder , 4 = order Sucessfull ]
  
  // const status = 4
  
  // const [status, setStatus] = useState(2);
  const [orderRejected, setOrderRejected] = useState(false);
  const [status, setstatus] = useState(1)


  useEffect(() => {
    if (status === 5) {
      setOrderRejected(true);
    } else {
      setOrderRejected(false);
      console.log('Value:', status);
    }
  }, [status, orderRejected]); // Adding orderRejected to the dependency array

  console.log(orderRejected);

  
  useEffect(() => {
    // Map status values to corresponding active steps
    switch (status) {
      case 0:
        setActiveStep(0); // Pending stage
        break;
      case 1:
        setActiveStep(1); // Order Confirmed stage
        break;
      case 2:
        setActiveStep(2); // Order Successful stage
        break;
      case 4:
        setActiveStep(3); // Order Completed stage
        break;
      case 5:
        setActiveStep(4); // Order Rejected stage
        break;
      default:
        setActiveStep(0); // Default to Pending stage
        break;
    }
  }, [status]);
  
            
            
const [orderdss, setOrderData] = useState(null)
const [example, setexample] = useState([])
const [orderQuntity, setorderQuntity] = useState([])


  useEffect(() => {
    axios.get(globalapi+`getviewcustorderdetails&order_num=${ordernumber}`)
      .then((res) => {
        const totaldata = res.data;
        const orderquntity = totaldata.order_total_qty
        setorderQuntity(orderquntity)
        setexample(totaldata)
        const userdata = totaldata.orderhdr;
  
        if (Array.isArray(userdata)) {
          setOrderData(userdata); // If userdata is an array, set it directly
        } else {
          setOrderData([userdata]); // If userdata is an object, convert it to an array
        }
      })
      .catch((err) => {
        console.warn(err);
        setOrderData([]); // Set to an empty array in case of an error
      });
  }, []);

  useEffect(() => {
    console.log("orderq", orderQuntity);
  }, [orderQuntity]); // Log changes to orderQuntity
console.log("total api",example)

// Receving order api data//////////////////////////////////////////////////////////////////////////////////////////////////////////////


const [isLoading, setIsLoading] = useState(false);

// loading bar

const [ordersitems, setordersitems] = useState([])
useEffect(()=>{
  axios.get(globalapi+`getviewcustorderdetails&order_num=${ordernumber}`).then((res)=>{
    setordersitems(res.data.orderdtl)
  })
},[])
console.log("New",ordersitems)
  return (
 
<div className="ordercontainer">
{
  isLoading ? (
   <p>hekko</p>
  ):(
  <>
  {orderdss !== null ? (
      orderdss.map((order, index) => (
        
<div className="oneheader">



{/* Order Detal start hear */}
<div className="detailcard" key={index}>
<div className="orderinfo">
  <p>{order.cat_name}, <span className='ordertext'>Order:{order.order_num}</span></p>

  <div>
      {order.cat_id === '29' ? (
            <p>
            Booking From : {order.order_est_sdttm}{" "}
             <span className='ordertext'>             
       
             </span>To {" "} 
             {order.order_est_edttm}
           </p>
      ) : (
        <p>.</p>
      )}
    </div>

   {/* {
  order.cat_id === '29' ? null : (
    <p>
     Date : {order.order_est_sdttm}{" "}
      <span className='ordertext'>
        <i className="fa-solid fa-arrow-right-arrow-left"></i>

      </span>{" "}
      {order.order_est_edttm}
    </p>
  )
}  */}

</div>
<hr />
<div className="customerdetails">
  <p>Total Quntity: <span className='ordertext'>{orderQuntity}</span></p>
  <p>Order Status: <span className='ordertext'>{order.order_status}</span></p>
</div>
<div className="exucativedetails">
  <p>Executive Name: <span className='ordertext'>None</span></p>
  <p>Mobile: <span className='ordertext'>None</span></p>
</div>
<div className="bookingdates">
  <p>Booking Date <span className='ordertext'>{order.order_create_date}</span></p>
</div>

<div className="customnote">
  <p>{order.custnote}</p>
</div>
</div>

</div>
))
    ) : (
      <p>Loading or no data available</p>
    )}


{/* Header two starts Hear */}
<div className="twoheader">
<div className="scrollaebledivs">
 {

      ordersitems.map((item, index) => (
        <div className="ordercard" key={index}>
          <div className="orderimage">
      
            <img src={item.scatpicture} alt={`Item ${index}`} />
          </div>
          <div className="ordertexts">
            <div className='ordertextmain'>
     
              <h4 className='foodname'>{item.custnote}</h4>
              <p className='quntity'>Quantity: <span className='ordertext'>{item.itemqty}</span></p>

              <p className='price'>Price: <del>₹{item.orginal_price}</del> <span style={{ color: 'green', fontSize: '13pt' }}>₹{item.offer_price}</span></p>
            </div>
          </div>
          {/* <div className="orderbutton">
            <button>Cancel</button>
          </div> */}
        </div>
      ))
} 
</div>
</div>


    
  
   </>
  )
}


    </div>
  );
};

export default OrderDetails;
