import React, { useState, useEffect } from 'react';
import '../Navbar/Nav.css';
import Logo from '../../Images/Logoimage.png';
import { Link } from 'react-router-dom';
import { useCart } from '../CartContext ';
import { useFoodCart } from '../FoodContext';

const Nav = () => {
  const [isActive, setisActive] = useState(true);
  const [isSticky, setIsSticky] = useState(false);
  const [firstname, setFirstname] = useState('');


  useEffect(() => {
    





    const storedUserData = localStorage.getItem('userData');

    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      const userFirstname = parsedUserData.first_name;
      setFirstname(userFirstname);
    }
  }, [localStorage.getItem('userData')]); // This will watch for changes in localStorage

  useEffect(() => {
    // This useEffect will execute whenever 'firstname' changes
    // You can perform actions based on the change in 'firstname' here
    if (firstname) {
      console.log('Firstname has changed:', firstname);
      // Perform other actions based on the 'firstname' change
    }
  }, [firstname]); //



  
  const Toggle = () => {
    setisActive(!isActive);
  };

  const TurnOff = () => {
    setisActive(true);
  };

  const handleLinkClick = () => {
    setisActive(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const { cartState: { cartItems: cartItems1 } } = useCart();
  const { state: { cart: cartItems2 } } = useFoodCart();

  // Calculate the total length of both carts
  const totalCartLength = cartItems1.length + cartItems2.length;


  return (
    <div className={`mainnavbar ${isSticky ? 'sticky' : ''}`}>
      <div className="navone">
        <div className="logoo">
          <button onClick={Toggle}><i className="fa-solid fa-bars"></i></button>
          <img src={Logo} alt="Logo" />
          <h4>Nature4</h4>
        </div>
        <ul className={isActive ? "navhide" : "navoneul"}>
          <li onClick={TurnOff}><i className="fa-solid fa-house-user"></i><Link to={'/'} onClick={handleLinkClick}>Home</Link></li>
          {/* <li onClick={TurnOff}><i className="fa-solid fa-comments"></i><Link to={'/'} onClick={handleLinkClick}>Notification</Link></li>
          <li onClick={TurnOff}><i className="fa-solid fa-face-sad-tear"></i><Link to={'/'} onClick={handleLinkClick}>Report</Link></li> */}
          <li onClick={TurnOff}><i class="fa-solid fa-box-open"></i><Link to={'/orders'} onClick={handleLinkClick}>Orders</Link></li>
          <li onClick={TurnOff}><i class="fa-solid fa-box-open"></i><Link to={'/termsandcondition'} onClick={handleLinkClick}>Terms & Conditions</Link></li>
          <li onClick={TurnOff}><i className="fa-solid fa-newspaper"></i><Link to={'/'} onClick={handleLinkClick}>About</Link></li>
        </ul>
      </div>

      <div className="navtwo">
        <div className="onenav">
        <span className="badge">{totalCartLength}</span>
          <Link to={'/cart'}><i className="fa-solid fa-cart-shopping"></i></Link>
        </div>
        <div className="twonav">
          <Link to={'/profile'}><p><i className="fa-solid fa-circle-user"></i> {firstname || "Login"}</p></Link>
        </div>
      </div>
    </div>
  );
};

export default Nav;
