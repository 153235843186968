import React, { useState } from 'react';
import axios from 'axios';
import '../Login/Login.css';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { globalapi } from '../../GlobalApi';

const Login = () => {
  const [phonenumber, setphonenumber] = useState('');
  const [passwd, setpasswd] = useState('');
  const [error, setError] = useState(null);

  const Nav = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null); // Reset error state on new login attempt

    const API_ENDPOINT = globalapi+'verify_login'; // Replace with your API endpoint

    try {
      const response = await axios.post(API_ENDPOINT, {
        phonenumber,
        passwd,
      });

      if (response.data.status === 'success') {
        // Save userid to localStorage upon successful login
        localStorage.setItem('userData', JSON.stringify(response.data));
        console.log('Login successful:', response.data);
        toast.success('Login Sucessfully');
        Nav('/')
       
        // Redirect or show success message upon successful login
      } else {
        setError('Login failed. Please check your credentials and try again.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Something went wrong. Please try again later.');
      }
    }
  };

  return (
    <>
      <div className="loginscreen">
        <div className="formdiv">
          <h3>Sign In or Create an Account</h3>
          <form onSubmit={handleLogin}>
            <div className="username">
              <input
                type="text"
                value={phonenumber}
                onChange={(e) => setphonenumber(e.target.value)}
                placeholder="Username or Number" required
              />
              <input
                type="password"
                value={passwd}
                onChange={(e) => setpasswd(e.target.value)}
                placeholder="Password" required
              />
             
              <button type="submit" className="loginbutton">
                Login <i className="fa-solid fa-right-to-bracket"></i>
              </button>
              <Link to={'/signup'} className="signuplink">
                Create An Account
              </Link>
            </div>
          </form>
           <div className="error">
             <h5>{error && <div className="error-message">{error}</div>}</h5>
           </div> 
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default Login;
