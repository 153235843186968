import React from 'react'
import { Link } from 'react-router-dom'
import '../Services/Service.css'
const Service = () => {
  return (
    <>
   <b> <h2 className='serviceheader'>Our Service's 24/7</h2></b>
    <div className="mainheadservice">

     <div className="serviceone">
     <Link to={'/roombooking'}>
       <div className="hotel">
       <i class="fa-solid fa-bed"></i>
       <div className="under">
       <h5>Room</h5>
       <Link to={'/roombooking'}>Checkin <i class="fa-solid fa-arrow-right"></i></Link>
       </div>
       </div>
       </Link>

       <Link to={'/Foodbooking'}>
       <div className="food">
       <i class="fa-solid fa-bowl-food"></i>
       <div className="under">
       <h5>Food</h5>
       <Link to={'/Foodbooking'}>Checkin <i class="fa-solid fa-arrow-right"></i></Link>
       </div>
       </div>
       </Link>

       <Link to={'/cabbooking'}>
       <div className="cab">
       <i class="fa-solid fa-bowl-food"></i>
       <div className="under">
       <h5>Cab</h5>
       <Link to={'/cabbooking'}>Checkin <i class="fa-solid fa-arrow-right"></i></Link>
       </div>
       </div>
       </Link>


 


     </div>
    </div>
    </>
  )
}

export default Service
