// FoodCartContext.js

import React, { createContext, useReducer, useContext, useEffect } from 'react';

const FoodCartContext = createContext();

const initialState = {
  cart: JSON.parse(localStorage.getItem('foodCart')) || [], // Retrieve cart data from local storage
};



const foodCartReducer = (state, action) => {
  switch (action.type) {
    case 'ORDER_FOOD':
      const { foodname, foodquantity, foodprice, foodid, foodimage,catagoryid,item_no,displayname,origenalprice,catagory_unite_discount } = action.payload;
      const updatedCart = [...state.cart];
      let itemIndex = -1;
    
      for (let i = 0; i < updatedCart.length; i++) {
        if (updatedCart[i].id === foodid) {
          itemIndex = i;
          break;
        }
      }
    
      if (itemIndex !== -1) {
        // If the item is already in the cart, increase its quantity by one
        updatedCart[itemIndex].quantity += 1;
      } else {
        // If the item is not in the cart, add it as a new item with a quantity of one
        const newItem = {
          id: foodid,
          name: foodname,
          quantity: 1,
          price: foodprice,
          image: foodimage,
          catagory_id: catagoryid,
          item_no: item_no,
          display_name:displayname,
          origenal_price: origenalprice,
          catagory_discount_unite: catagory_unite_discount,
          

        };
        updatedCart.push(newItem);
      }
    
      // Save the updated cart to local storage
      localStorage.setItem('foodCart', JSON.stringify(updatedCart));
    
      return {
        ...state,
        cart: updatedCart,
      };
 
      case 'REMOVE_FROM_FOOD_CART':
        const itemIdToRemove = action.payload.id;
        const updatedFoodCart = state.cart.filter((item) => item.id !== itemIdToRemove);
        // Save the updated cart to local storage
        localStorage.setItem('foodCart', JSON.stringify(updatedFoodCart));
        return {
          ...state,
          cart: updatedFoodCart,
        };

    case 'INCREMENT_FOOD_QUANTITY':
      // Handle increasing the quantity of a food item in the cart
      const itemIdToIncrement = action.payload.id;
      const updatedCartIncrement = state.cart.map((item) => {
        if (item.id === itemIdToIncrement) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
      // Save the updated cart to local storage
      localStorage.setItem('foodCart', JSON.stringify(updatedCartIncrement));
      return {
        ...state,
        cart: updatedCartIncrement,
      };

    case 'DECREMENT_FOOD_QUANTITY':
      // Handle decreasing the quantity of a food item in the cart
      const itemIdToDecrement = action.payload.id;
      const updatedCartDecrement = state.cart.map((item) => {
        if (item.id === itemIdToDecrement) {
          const newQuantity = item.quantity - 1;
          return { ...item, quantity: newQuantity >= 1 ? newQuantity : 1 };
        }
        return item;
      });
      // Save the updated cart to local storage
      localStorage.setItem('foodCart', JSON.stringify(updatedCartDecrement));
      return {
        ...state,
        cart: updatedCartDecrement,
      };

    default:
      return state;
  }
};

export const FoodCartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(foodCartReducer, initialState);

  return (
    <FoodCartContext.Provider value={{ state, dispatch }}>
      {children}
    </FoodCartContext.Provider>
  );
};

export const useFoodCart = () => {
  return useContext(FoodCartContext);
};
